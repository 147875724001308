import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = ["| 스테이지파이브 9월 알뜰폰 유심 가입자 특별 혜택 … 눈으로 보는 통화 비토(VITO)앱 무료 이용권 제공"]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/21/zmRSnnCi.png" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure1" />
      </div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)가 8일부터 알뜰폰(MVNO) 핀다이렉트샵 유심 요금제 신규 가입자 전원에게 인공지능 전화앱
      ‘비토(VITO)’ 베이직 멤버십 3개월 무료 이용권을 제공한다. 베이직 멤버십 3개월 이용 혜택 이후에도 무료버전으로 자동 전환돼
      과금 없이 지속하여 이용할 수 있다.
      <br />
      <br />
      핀다이렉트샵 유심 요금제 5종이 최근 MZ세대에 큰 호응을 받고 있는 만큼, 신규 혜택 역시 주 가입자 층에 초점을 맞췄다.
      <br />
      <br />
      비토는 최근 ‘일잘러’ MZ세대 직장인의 업무 필수 앱으로 떠오르는 서비스이다. 음성인식 AI 스타트업 리턴제로(대표 이참솔)가
      개발한 앱으로, 통화 녹음을 텍스트로 자동 변환해 메신저 채팅처럼 보여준다. ARS 음성 검출 기술개발로 사람의 육성은 물론, ARS
      음성도 구분해낼 수 있을 뿐 아니라, 통화 녹음을 손쉽게 검색해 확인할 수도 있다. 부분 재생, 편집, 내보내기, 메모와 같은 다양한
      기능을 지원하는 것도 특징이다.
      <br />
      <br />
      핀다이렉트샵 알뜰폰 유심 요금제는 신규 혜택에 더하여 첫 달 통신요금 무료 및 5~6개월간 27~30%의 요금 할인도 적용된다. 최대
      2만원의 제휴카드 추가 할인 혜택, 최대 17만원의 친구 추천 이벤트 혜택도 받을 수 있다.
      <br />
      <br />
      스테이지파이브 관계자는 “알뜰폰 유심 요금제가 젊은 층에 많은 사랑을 받고 있는 만큼, 이들에게 필요하면서도 참신한 서비스와
      제휴하여 이용자들께 유용한 통신 사용 경험을 전할 계획이다”고 전했다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 9월 8일 즉시 배포 가능"
      pageInfo="*총 1매 / 첨부 1매"
      title="“통화 많은 일잘러라면, 알뜰 통신요금에 AI전화앱 이용권 챙겨가세요”"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
